import styled from 'styled-components';
import { ifProp, palette } from 'styled-tools';

import { minWidth } from '@calm-web/design-system';

import FullscreenBackground from '@/components/image/FullscreenBackground';
import FullscreenOverlay from '@/components/image/FullscreenOverlay';
import GridWrapper from '@/components/ui/GridWrapper';

export const Wrapper = styled.main<{
	isBannerVisible: boolean;
}>`
	position: relative;
	padding-top: ${ifProp('isBannerVisible', '40px', '0')};
`;

export const Grid = styled(GridWrapper)`
	min-height: 100vh;
	max-width: 480px;

	${minWidth('tablet')} {
		grid-template-columns: 1fr;
	}

	${minWidth('desktop')} {
		grid-template-columns: 1fr 1fr;
		max-width: 960px;
	}

	${minWidth('desktopLarge')} {
		max-width: 1168px;
	}
`;

export const Background = styled(FullscreenBackground)`
	z-index: -1;
`;

export const Overlay = styled(FullscreenOverlay)`
	z-index: 0;
`;

export const ContentWrapper = styled.div<{
	$centerContent: boolean;
}>`
	align-items: center;
	color: ${palette('white')};
	display: flex;
	flex-direction: column;
	margin: 0 auto 24px;
	padding-top: 96px;
	position: relative;
	text-align: center;
	z-index: 1;

	${minWidth('tablet')} {
		padding-top: 136px;
	}

	${minWidth('desktop')} {
		align-items: flex-start;
		justify-content: ${p => (p.$centerContent ? 'center' : 'flex-start')};
		margin: 0;
		text-align: left;
	}
`;

export const TextContainer = styled.div`
	display: grid;
	grid-row-gap: 8px;
	margin-bottom: 8px;

	${minWidth('mobileLarge')} {
		margin-bottom: 24px;
	}
`;

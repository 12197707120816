import { useRouter } from 'next/router';
import { useState } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';

import {
	FocusRingVariants,
	QuestionnaireTile,
	Text,
	CalmGradients,
	FontSizes,
	FontWeights,
} from '@calm-web/design-system';

import { SelectedTiles } from '@/components/PreSignupFlow/PreSignupProvider/types';
import { Tile } from '@/components/ui/QuestionnaireTile/types';
import { useAnalytics } from '@/hooks/analytics';
import { useUserState, useLocaleState } from '@/hooks/store';
import genericMessages from '@/messages/messages';
import { postQuestionnairePoll } from '@/utils/endpoints';
import redirect from '@/utils/legacy/redirect';
import { userCanTrial } from '@/utils/subscriptions';

import { onboardingTiles } from './data';
import messages from './messages';
import { ContinueButton, OnboardingTiles, OnboardingTilesGroup } from './styles';

const initSelectedGoals = (queryGoals: string) => {
	return queryGoals.split(',').reduce((accu, curr) => {
		return {
			...accu,
			[curr]: {
				id: curr,
			},
		};
	}, {});
};

const OnboardingQuestionnaire = () => {
	const { formatMessage } = useIntl();
	const { logEvent } = useAnalytics();
	const { query } = useRouter();
	const user = useUserState();
	const locale = useLocaleState();

	const [isRedirecting, setIsRedirecting] = useState(false);
	const [selectedTiles, setSelectedTiles] = useState<SelectedTiles>(() => {
		if (!query?.goals || typeof query?.goals !== 'string') {
			return {};
		}
		return initSelectedGoals(query?.goals);
	});

	if (user && !userCanTrial(user)) return null;

	const isSelected = (tileId: string) => {
		return !!selectedTiles[tileId];
	};

	const onTileClick = (tile: Tile) => {
		const { id } = tile;
		const newSelectedTiles = { ...selectedTiles, [id]: tile };

		if (isSelected(id)) {
			delete newSelectedTiles[id];
		}

		setSelectedTiles(newSelectedTiles);
	};

	const onContinue = async () => {
		setIsRedirecting(true);

		const selectedTilesArr = Object.keys(selectedTiles)
			.map(i => selectedTiles[i])
			.filter(i => i);

		const answers = selectedTilesArr.map(({ id }) => id);

		await postQuestionnairePoll([
			{
				poll_name: 'goals_web_stress_onboarding',
				question: 'What can we help with today?',
				answers,
			},
		]);

		logEvent({
			eventName: `FTUE : Goal Questionnaire : Proceeded`,
			eventProps: {
				choices: answers,
				num_choices: selectedTilesArr?.length,
			},
		});

		logEvent({
			eventName: 'Landing Page : Signup Flow CTA : Clicked',
		});

		const querifiedAnswers = answers.join();

		// we do not want calm_to_work to influence the user flow
		const answersToDetermineFlow = answers.filter(answer => answer !== 'calm_to_work');
		if (answersToDetermineFlow.includes('sleep_quality') && answersToDetermineFlow.length === 1) {
			redirect(`/signup-flow?focus=sleep&goals=${querifiedAnswers}`);
		} else {
			redirect(`/signup-flow?focus=stress&goals=${querifiedAnswers}`);
		}
	};

	const titleId = 'onboarding-goals-title';

	return (
		<>
			<Text
				noMargin
				el="h3"
				size={locale === 'ja' ? FontSizes.base : FontSizes.lg}
				weight={FontWeights.Medium}
				id={titleId}
			>
				{formatMessage(messages.questionnaireCta)}
			</Text>
			<OnboardingTilesGroup role="group" aria-labelledby={titleId}>
				<OnboardingTiles>
					{onboardingTiles.map((tile, i) => (
						<li key={tile.id}>
							<QuestionnaireTile
								colors={{
									color1: CalmGradients[i][0],
									color2: CalmGradients[i][1],
								}}
								title={formatMessage(tile.title as MessageDescriptor)}
								onClick={(): void => onTileClick(tile)}
								isSelected={isSelected(tile.id)}
								Icon={tile.Icon}
								focusRingVariant={FocusRingVariants.Light}
								role="checkbox"
							/>
						</li>
					))}
				</OnboardingTiles>
			</OnboardingTilesGroup>
			<ContinueButton
				fullWidth
				onPress={onContinue}
				backgroundColor="white"
				textColor="gray6"
				isLoading={isRedirecting}
				isActive={Object.keys(selectedTiles).length > 0}
				loaderColor="gray6"
			>
				{formatMessage(genericMessages.continue)}
			</ContinueButton>
		</>
	);
};

export default OnboardingQuestionnaire;

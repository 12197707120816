import { useContext } from 'react';
import { useIntl } from 'react-intl';

import { Text, FontSizes, FontWeights } from '@calm-web/design-system';

import { MAIN_CONTENT_ID } from '@/components/accessibility/SkipToMain';
import { OfferBannerContext } from '@/components/app/layout/OfferBannerProvider';
import OnboardingQuestionnaire from '@/components/homepage/OnboardingQuestionnaire';
import { useAnalytics } from '@/hooks/analytics';
import { useUserState, useLocaleState } from '@/hooks/store';
import { useComponentDidMount } from '@/hooks/utils/useComponentDidMount';
import { userCanTrial } from '@/utils/subscriptions';

import messages from './messages';
import { Background, ContentWrapper, Grid, TextContainer, Overlay, Wrapper } from './styles';

const OnboardingHomepage = () => {
	const { formatMessage } = useIntl();
	const { logEvent } = useAnalytics();
	const user = useUserState();
	const locale = useLocaleState();

	const isSubscribedUser = userCanTrial(user);
	const { isBannerVisible } = useContext(OfferBannerContext);

	useComponentDidMount(() => {
		logEvent({
			eventName: 'Screen : Viewed',
			eventProps: {
				active_flow: 'stress',
				screen: 'FTUE : Goal Questionnaire',
			},
		});
	});

	return (
		<Wrapper id={MAIN_CONTENT_ID} isBannerVisible={isBannerVisible}>
			<Background src="/_n/images/jasper-lake.webp" />
			<Overlay gradient="gradientHome" mobileGradient="gradientHomeMobile" />
			<Grid columns={2}>
				<ContentWrapper $centerContent={!isSubscribedUser}>
					<TextContainer>
						<Text
							noMargin
							el="h1"
							size={locale === 'ja' ? FontSizes.lg : FontSizes['4xl']}
							weight={FontWeights.Light}
						>
							{formatMessage(messages.hero)}
						</Text>
						<Text noMargin el="h2" size={FontSizes.lg} weight={FontWeights.Regular}>
							{formatMessage(messages.heroSub)}
						</Text>
					</TextContainer>
					<OnboardingQuestionnaire />
				</ContentWrapper>
			</Grid>
		</Wrapper>
	);
};

export default OnboardingHomepage;

import { defineMessages } from 'react-intl';

export default defineMessages({
	hero: {
		id: 'homepage.hero',
		defaultMessage: 'Find Your Calm',
		description: 'Title text on the homepage',
	},
	heroSub: {
		id: 'homepage.onboarding.heroSub',
		defaultMessage: 'Our goal is to help you improve your health and happiness.',
		description: 'Subtitle text on the homepage',
	},
});

import styled from 'styled-components';
import { palette } from 'styled-tools';

import { Button, FocusRingVariants, minWidth } from '@calm-web/design-system';

export const OnboardingTilesGroup = styled.div`
	margin: 16px 0;
	width: 100%;

	${minWidth('mobileLarge')} {
		margin: 16px 0 40px;
	}
`;

export const OnboardingTiles = styled.ul`
	list-style: none;
	padding: 0;
`;

export const ContinueButton = styled(Button).attrs({
	focusRingVariant: FocusRingVariants.Light,
})<{
	isActive: boolean;
}>`
	border: 1px ${palette('gray6')} solid;
	${p =>
		p.isActive
			? `
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
      `
			: `
      opacity: 0;
      visibility: hidden;
      transform: translateY(-4px);
    `}
`;
